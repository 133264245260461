import { inject, Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { environment } from '../../environments/environment'
import { HttpClient } from '@angular/common/http';
import { Buffer } from 'buffer';

export interface SaveView {
  view_label: string,
  settings: {
    columnState?: any,
    columnGroupState?: any,
    sortModel?: any,
    filterModel?: any,
  }
}

export interface UserView extends SaveView {
  view_id: string,
  timestamp: string
}

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  private userSettings = new ReplaySubject(1)
  private http = inject(HttpClient)

  constructor() {}

  public saveUserSettings(data: any) {
    this.http.post(`${environment.yomaApiURL}/user/settings`, data).subscribe(res => this.userSettings.next(res))
  }

  public setUserSettings(data: any) {
    this.userSettings.next(data)
  }

  public getUserSettings(): Observable<any> {
    return this.userSettings
  }

  public saveUserView(data: SaveView) {
    return this.http.post(`${environment.yomaApiURL}/user/settings/views`, data)
  }

  public getUserViews() {
    return this.http.get(`${environment.yomaApiURL}/user/settings/views`)
  }

  public deleteUserView(data: UserView) {
    let viewId = Buffer.from(data.view_id).toString('base64')
    return this.http.delete(`${environment.yomaApiURL}/user/settings/views/${viewId}`)
  }

  public updateUserView(data: UserView) {
    let viewId = Buffer.from(data.view_id).toString('base64')
    return this.http.put(`${environment.yomaApiURL}/user/settings/views/${viewId}`, data)
  }

}
