<div class="not-found-page">
  <div class="not-found-content">
    <img class="logo" src="assets/logo.png" alt="logo">
    <img src="assets/not-found-img.png" alt="not-found" height="112" width="128">
    <div class="text-content">
      <span nz-typography nzType="secondary">Fehler 404</span>
      <h2 nz-typography>Seite nicht gefunden</h2>
      <span
        nz-typography>Die aufgerufene Seite existiert nicht oder du hast keine Berechtigung darauf zuzugreifen.</span>
    </div>
    <button nz-button nzType="primary" nzDanger nzSize="large" routerLink="/">
      <span nz-icon nzType="arrow-left" nzTheme="outline"></span>
      Zurück zur Startseite
    </button>
  </div>
</div>
