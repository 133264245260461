import {Component, Input, Output, EventEmitter, inject} from '@angular/core';
import {ACTIONS, TrackingService} from "../../services/tracking/tracking.service";

@Component({
  selector: 'tt-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {
  @Input() placeholder: string = "Suche"

  @Output() searchValue = new EventEmitter<string>();
  searchInputValue: string = "";
  trackingService = inject(TrackingService);

  setSearchValue = () => {
    this.searchValue.emit(this.searchInputValue.toLowerCase())
    this.trackingService.trackEvent(ACTIONS.neuwagenmonitor_search_button_clicked, {value: this.searchValue})
    this.searchInputValue = "";
  }
}
