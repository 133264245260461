import {Component, inject} from '@angular/core';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexYAxis,
  ApexPlotOptions,
  ApexLegend,
  ApexFill,
  ApexStroke,
  NgApexchartsModule,
} from "ng-apexcharts";
import {NzIconDirective} from "ng-zorro-antd/icon";
import {FronteggAppService} from "@frontegg/angular";


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  plotOptions: ApexPlotOptions;
  stroke?: ApexStroke;
  fill?: ApexFill;
  colors: string[];
  legend: ApexLegend;
};

@Component({
  selector: 'tt-statistics-chart',
  standalone: true,
  imports: [ NgApexchartsModule, NzTypographyModule, NzCardModule, NzAvatarModule, NzButtonModule, NzIconDirective, NzIconModule ],
  templateUrl: './statistics-chart.component.html',
  styleUrl: './statistics-chart.component.scss'
})
export class StatisticsChartComponent {

  fronteggAppService = inject(FronteggAppService)
  public chartOptions : ChartOptions;

  constructor() {

    this.chartOptions = {
      series: [
        {
          name: "Auslieferungen",
          data: [18, 28, 70, 56, 64, 80, 50, 48, 30, 24, 60, 51]
        },
        {
          name: "Bestelleingänge",
          data: [15, 25, 35, 45, 55, 65, 44, 39, 21, 20, 52, 47]
        }
      ],
      chart: {
        type: "bar",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%",
          borderRadius: 5,
          borderRadiusApplication: 'end',
          borderRadiusWhenStacked: "last"
        }
      },
      colors: ["#01b574", "#f34c23"],
      xaxis: {
        categories: [
          "Januar", "Februar", "März", "April", "Mai", "Juni",
          "Juli", "August", "September", "Oktober", "November", "Dezember"
        ]
      },
      yaxis: {
        title: {
          text: "Vehicles"
        }
      },
      legend: {
        position: "top"
      }
    };
  }


  showAdminPortal(): void {
    this.fronteggAppService?.showAdminPortal()
  }

}
