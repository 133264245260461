import { Component } from '@angular/core';
import { CommonModule } from "@angular/common";
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import {RouterLink} from "@angular/router";

@Component({
  selector: 'tt-not-found',
  standalone: true,
  imports: [
    CommonModule, NzTypographyModule, NzButtonModule, NzIconModule, RouterLink
  ],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss'
})
export class NotFoundComponent {

}
