<div nz-page-header class="page-header">
  <div class="header-container">
    <div class="menu-wraper">
<!--      <a class="header-logo" routerLink="">-->
<!--        <img nz-page-header-avatar src="assets/logo.png" alt="img"/>-->
<!--      </a>-->
      <ul class="menu-lg" nz-menu nzMode="horizontal">
        <li nz-menu-item routerLink="">
          <img nz-page-header-avatar ngSrc="assets/logo.png" alt="img" height="80" width="138"/>
        </li>
        <li *ngIf="hasFeature('feature-news-feed')" class="hide-mobile"nz-menu-item nzMatchRouter routerLink="/news"><h3>NEWS</h3></li>
        <li *ngIf="hasFeature('feature-vehicles')" class="hide-mobile" nz-menu-item nzMatchRouter routerLink="/vehicles">
          <h3>Meine Fahrzeuge</h3>
        </li>
        <li *ngIf="hasFeature('feature-expert-view') && hasFeature('feature-vehicles')" class="hide-mobile"nz-menu-item nzMatchRouter routerLink="/expert-view">
          <h3>Expertensicht</h3>
        </li>
        <li *ngIf="hasFeature('feature-new-vehicle-monitor')" class="hide-mobile" nz-menu-item nzMatchRouter routerLink="/neuwagenmonitor"
            data-testid="menu-item-neuwagenmonitor">
          <h3>Neuwagenmonitor</h3>
        </li>
        <li class="hide-mobile" nz-menu-item nzMatchRouter routerLink="/scan"><h3>Scan</h3></li>
      </ul>
    </div>
    <nz-space class="menu-space" *ngIf="user">
      <nz-space class="menu-lg">
        <nz-avatar
          *nzSpaceItem
          nzIcon="user"
          nzSize="large"
          nzSrc="{{ user.profilePictureUrl }}"
        ></nz-avatar>
        <div *nzSpaceItem class="user-menu">
          <span nz-icon nzType="down" (click)="open()" class="display-mobile"></span>
          <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu" class="hide-mobile">
            <div class="user-info-menu-item">
              <span nz-typography class="user-name">Hey {{ user.name }}!</span>
              <span nz-typography nzType="secondary">{{tenantState.activeTenant?.name }}</span>
            </div>
            <span nz-icon nzType="down"></span>
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item class="bordered display-mobile">
                <div class="user-info-menu-item">
                  <span nz-typography class="user-name">Hey {{ user.name }}!</span>
                  <span nz-typography nzType="secondary">{{tenantState.activeTenant?.name }}</span>
                </div>
              </li>
              <li nz-menu-item class="bordered user-menu-item-link" (click)="openChangeTenantModal(tplTenantChooserContent, tplTenantChooserFooter)" *ngIf="tenantState.tenants.length > 1">
                <span nz-typography nzType="secondary">Umgebung wechseln</span>
                <div class="menu-item-content">
                  <div class="menu-item">
                    <span nz-icon nzType="swap_horiz" nzTheme="outline" class="menu-icon"></span>
                    <span>{{tenantState.activeTenant?.name }}</span>
                  </div>
                  <span nz-icon nzType="chevron_right" nzTheme="outline"></span>
                </div>
              </li>
              <li nz-menu-item class="bordered" (click)="showAdminPortal()">
                <span nz-icon nzType="account_circle" nzTheme="outline" class="menu-icon"></span>
                {{ isAdmin() ? 'Profil & Benutzerverwaltung' : 'Profilverwaltung'}}
              </li>
              <li nz-menu-item [routerLink]="['features']" *ngIf="!isUserGuest">
                <span nz-icon nzType="toggle_on" nzTheme="outline" class="menu-icon" ></span>
                Features
              </li>
              <li *ngIf="hasFeature('feature-keyaccount')" nz-menu-item [routerLink]="['keyaccount']">
                <span nz-icon nzType="table_eye" nzTheme="outline" class="menu-icon"></span>
                Kunden & Partner
              </li>
              <li *ngIf="isAdmin() && hasFeature('feature-vehicles') " nz-menu-item [routerLink]="['change-column-names']">
                <span nz-icon nzType="edit" nzTheme="outline" class="menu-icon"></span>
                Spaltennamen ändern
              </li>
              <li nz-menu-item *ngIf="!isUserGuest" [routerLink]="['developer']" (click)="developerClicked()" >
                <span nz-icon nzType="power" nzTheme="outline" class="menu-icon"></span>
                API Schnittstelle
              </li>
              <li nz-menu-item *ngIf="!isUserGuest" class="bordered" [routerLink]="['importer']">
                <span nz-icon nzType="input_circle" nzTheme="outline" class="menu-icon"></span>
                Importer
              </li>
              <li
                nz-menu-item
                class="user-menu-item-link"
                (click)="supportClicked()"
                onclick="window.open('https://yoma-track-trace.helpjuice.com/', '_blank')"
              >
                <div class="menu-item-content">
                  <div class="menu-item">
                    <span nz-icon nzType="menu_book" nzTheme="outline" class="menu-icon"></span>
                      Support
                  </div>
                  <span nz-icon nzType="open_in_new" nzTheme="outline"></span>
                </div>
              </li>
              <li nz-menu-item (click)="logout()">
                <span nz-icon nzType="logout" nzTheme="outline" class="menu-icon"></span>
                Ausloggen
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </nz-space>

      <div class="mobile-menu">
        <ul nz-menu nzMode="horizontal" style="display: flex">
          <ng-container *ngFor="let item of getMainMenuItems()">
            <li nz-menu-item *ngIf="!item.action" [routerLink]="item.route">
              <div class="mobile-menu-item">
                <span nz-icon [nzType]="item.icon" class="mobile-menu-icon"></span>
                <span class="menu-text-item">{{ item.label }}</span>
              </div>
            </li>
            <li nz-menu-item *ngIf="item.action === 'searchModal'" (click)="openSearchModal(tplContent, tplFooter)">
              <div class="mobile-menu-item">
                <span nz-icon [nzType]="item.icon" class="mobile-menu-icon" style="font-size: 30px; margin-top: 2px"></span>
                <span class="menu-text-item">{{ item.label }}</span>
              </div>
            </li>
          </ng-container>

          <li *ngIf="getDropdownMenuItems().length > 0" nz-menu-item nzMatchRouter nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <div class="mobile-menu-item">
              <span nz-icon nzType="menu" class="mobile-menu-icon"></span>
              <span class="menu-text-item">Mehr</span>
            </div>
          </li>
        </ul>
      </div>

      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu>
          <ng-container *ngFor="let item of getDropdownMenuItems()">
            <li nz-menu-item [routerLink]="[item.route]">
              <span nz-icon [nzType]="item.icon" nzTheme="outline" class="menu-icon"></span>
              <span>{{ item.label }}</span>
            </li>
          </ng-container>
        </ul>
      </nz-dropdown-menu>
      <nz-drawer
        [nzClosable]="true"
        [nzVisible]="visible"
        [nzPlacement]="placement"
        nzTitle="Menu"
        (nzOnClose)="close()"
      >
        <ng-container *nzDrawerContent>
          <ul nz-menu (click)="close()" style="border-right: none">
            <li nz-menu-item class="bordered display-mobile">
              <div class="user-info-menu-item">
                <span nz-typography class="user-name">Hey {{ user.name }}!</span>
                <span nz-typography nzType="secondary">{{tenantState.activeTenant?.name }}</span>
              </div>
            </li>
            <li nz-menu-item class="bordered user-menu-item-link"
                (click)="openChangeTenantModal(tplTenantChooserContent, tplTenantChooserFooter)"
                *ngIf="tenantState.tenants.length > 1" style="height: auto"
            >
              <span nz-typography nzType="secondary">Umgebung wechseln</span>
              <div class="menu-item-content">
                <div class="menu-item">
                  <span nz-icon nzType="swap_horiz" nzTheme="outline" class="menu-icon"></span>
                  <span>{{tenantState.activeTenant?.name }}</span>
                </div>
                <span nz-icon nzType="chevron_right" nzTheme="outline"></span>
              </div>
            </li>
            <li nz-menu-item class="bordered">
              <span nz-icon nzType="account_circle" nzTheme="outline" class="menu-icon"></span>
              <span (click)="showAdminPortal()">
                {{ isAdmin() ? 'Profil & Benutzerverwaltung' : 'Profilverwaltung'}}
              </span>
            </li>
            <li nz-menu-item *ngIf="!isUserGuest">
              <span nz-icon nzType="toggle_on" nzTheme="outline" class="menu-icon"></span>
              <a [routerLink]="['features']">Features</a>
            </li>
            <li *ngIf="hasFeature('feature-keyaccount')" nz-menu-item>
              <span nz-icon nzType="table_eye" nzTheme="outline" class="menu-icon"></span>
              <a [routerLink]="['keyaccount']">Kunden & Partner</a>
            </li>
            <li *ngIf="isAdmin()" nz-menu-item>
              <span nz-icon nzType="edit" nzTheme="outline" class="menu-icon"></span>
              <a [routerLink]="['change-column-names']">Spaltennamen ändern</a>
            </li>
            <li nz-menu-item *ngIf="!isUserGuest">
              <span nz-icon nzType="power" nzTheme="outline" class="menu-icon"></span>
              <a [routerLink]="['developer']" (click)="developerClicked()">API Schnittstelle</a>
            </li>
            <li nz-menu-item *ngIf="!isUserGuest" class="bordered">
              <span nz-icon nzType="input_circle" nzTheme="outline" class="menu-icon"></span>
              <a [routerLink]="['importer']">Importer</a>
            </li>
            <li nz-menu-item class="user-menu-item-link">
              <div class="menu-item-content">
                <div class="menu-item">
                  <span nz-icon nzType="menu_book" nzTheme="outline" class="menu-icon"></span>
                  <a href="https://yoma-track-trace.helpjuice.com/" target="_blank" (click)="supportClicked()">
                    Support
                  </a>
                </div>
                <span nz-icon nzType="open_in_new" nzTheme="outline"></span>
              </div>
            </li>
            <li nz-menu-item>
              <span nz-icon nzType="logout" nzTheme="outline" class="menu-icon"></span>
              <span (click)="logout()">Ausloggen</span>
            </li>
          </ul>
        </ng-container>
      </nz-drawer>
    </nz-space>
    <ng-template #tplContent>
      <form nz-form>
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24">Marke</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <nz-select
              class="info-select"
              [(ngModel)]="selectedBrand"
              nzPlaceHolder="Beliebig"
              [ngModelOptions]="{standalone: true}"
            >
              <nz-option *ngFor="let brand of vehicleService.vehicleBrands" nzValue="{{brand}}" nzLabel="{{brand}}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24">Hersteller Bestell-Nr.</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <input nz-input [(ngModel)]="selectedOrderId" placeholder="Beliebig"
                   [ngModelOptions]="{standalone: true}"/>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24">Auftragstyp</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <nz-select
              class="info-select"
              [(ngModel)]="selectedOrderType"
              nzPlaceHolder="Beliebig"
              [ngModelOptions]="{standalone: true}"
            >
              <nz-option *ngFor="let orderType of vehicleService.vehicleOrderTypes" nzValue="{{orderType}}"
                         nzLabel="{{orderType}}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24">Kundenname</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <input nz-input [(ngModel)]="selectedCustomerName" placeholder="Beliebig"
                   [ngModelOptions]="{standalone: true}"/>
          </nz-form-control>
        </nz-form-item>
      </form>
    </ng-template>
    <ng-template #tplFooter let-ref="modalRef">
      <button nz-button (click)="handleModalClose(ref)">Abbrechen</button>
      <button nz-button nzType="primary" nzDanger routerLink="/vehicles" (click)="setFilterData(ref)">Suche</button>
    </ng-template>

    <ng-template #tplTenantChooserContent>
      <p nz-typography nzType="secondary">Ihre E-Mail ist mit mehreren Mandaten verbunden. Bei welcher möchten Sie sich anmelden?</p>
      <div *ngIf="tenantState.tenants.length <= 5">
        <div
          class="tenant-chooser-item"
          *ngFor=" let tenant  of tenantState.tenants"
          [class.active]="tenant.tenantId === selectedTenantId"
          (click)="setActiveTenant(tenant.tenantId)"
        >
          {{tenant.name}}
          <span nz-icon nzType="check-circle" nzTheme="fill" class="tenant-item-icon"></span>
        </div>
      </div>
      <div *ngIf="tenantState.tenants.length > 5" style="width: 100%">
        <nz-select [(ngModel)]="selectedTenantId" style="width: 100%">
          <nz-option
            *ngFor="let tenant of tenantState.tenants"
            [nzValue]="tenant.tenantId"
            [nzLabel]="tenant?.name"
          ></nz-option>
        </nz-select>
      </div>
    </ng-template>
    <ng-template #tplTenantChooserFooter let-ref="modalRef">
      <button nz-button (click)="ref.destroy()">Abbrechen</button>
      <button nz-button nzType="primary" nzDanger  [nzLoading]="isLoading" (click)="switchTenant(ref)">Zu Umgebung wechseln</button>
    </ng-template>

  </div>
</div>

