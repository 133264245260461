import {Component, inject, OnInit} from '@angular/core';
import {ACTIONS, TrackingService} from "../../services/tracking/tracking.service";

@Component({
  selector: 'tt-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent implements OnInit{
  trackingService = inject(TrackingService);
  currentYear: number = 2024;

  impresumClicked(){
    this.trackingService.trackEvent(ACTIONS.footer_impresum_button_clicked, {});
  }
  cookiesClicked(){
    this.trackingService.trackEvent(ACTIONS.footer_cookies_button_clicked, {});
  }
  datenschutzClicked(){
    this.trackingService.trackEvent(ACTIONS.footer_datenschutz_button_clicked, {});
  }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
  }

}
