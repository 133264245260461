import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {EMPTY, Observable, BehaviorSubject} from "rxjs";
import {
  DateRangeFilter,
  TermFilter,
  VehicleFilters,
  WildcardSearch,
  YomaQueryParams
} from '../models/filters';
import { environment } from '../../environments/environment'
import {YomaTenant} from "../models/yomaTenant";

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  filters: TermFilter[] = [];
  dateRangeFilters: DateRangeFilter | undefined;
  statusFilter: string = "";
  sort: string = ""
  archiveFilter: string = "active";
  isFavouriteFilterActive = false;
  wildcardSearch: WildcardSearch[] = [];
  vehicleBrands: string[] = [];
  vehicleOrderTypes: string[] = [];
  vehicleFilters = new BehaviorSubject<VehicleFilters>( {
    filter: [],
    filterMap: new Map<string, any>(),
    search: "",
    wildcard_search: [],
    status: {
      status: "",
      sort: ""
    },
    archive: "active",
    sort: []
  });

  constructor(private http: HttpClient) { }

  setFilters(filters: VehicleFilters) {
    this.vehicleFilters.next(filters)
  }

  removeFilter(event: Event, key: string) {
    // Need this to prevent all nz-tags to be closed
    event.preventDefault()
    event.stopPropagation()
    switch (key) {
      case 'status':
        this.vehicleFilters.getValue().status = { status: "", sort: "" }
        break;
      case 'wildcard_search':
        this.vehicleFilters.getValue().wildcard_search = []
        break;
      case 'search':
        this.vehicleFilters.getValue().search = ""
        break;
      case 'all_filters':
        this.vehicleFilters.getValue().wildcard_search = []
        this.vehicleFilters.getValue().status = { status: "", sort: "" }
        this.vehicleFilters.getValue().archive = "active"
        this.vehicleFilters.getValue().search = ""
        this.vehicleFilters.getValue().filterMap.clear()
        this.vehicleFilters.getValue().sort = []
        break;
      default:
        this.vehicleFilters.getValue().filterMap.delete(key)
        break;
    }
    this.vehicleFilters.next(this.vehicleFilters.getValue())
  }

  getVehicles(filters?: any, params?: any): Observable<any> {
    if(!filters) {
      this.vehicleFilters.getValue().filter = [...this.vehicleFilters.getValue().filterMap.values()]
      filters = JSON.parse(JSON.stringify(this.vehicleFilters.getValue()))
      delete filters.filterMap
    }
    return this.http.post(`${environment.yomaApiURL}/vehicles`, filters, {params: params})
  }

  getVehicleDetails(id: string){
    return this.http.get(`${environment.dataApiURL}/vehicles/${id}`);
  }

  getNoAuthVehicleDetails(id: string){
    return this.http.get(`${environment.dataApiURL}/noauth/vehicles/${id}`);
  }

  getDistinctValues(attr: string): Observable<any> {
    return this.http.get(`${environment.yomaApiURL}/value_list/${attr}`);
  }

  getVehicleStatus(type: string, params?: YomaQueryParams): Observable<any> {
    const queryParams = new HttpParams({ fromObject: params as any})
    if (type == ('favorites' || '')) {
      return EMPTY
    }
    return this.http.get(`${environment.yomaApiURL}/vehicles/${type}`, { params: queryParams })
  }

  getColorMap(make: string) {
    return this.http.get(`https://cdn.imagin.studio/getPaints?&customer=yoma-de&target=make&make=${make}`)
  }

}
