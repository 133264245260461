import {Component, ElementRef, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { Angulartics2Segment } from 'angulartics2';
import {TrackingService} from "../../services/tracking/tracking.service";
import { filter, map, Subscription } from "rxjs";
import { FronteggAuthService, FronteggAppService } from '@frontegg/angular';
import { Event, NavigationEnd, Router, Scroll } from '@angular/router';
import {UserInfoService} from "../../services/user-info.service";
import { HttpClient } from '@angular/common/http';
import { UserSettingsService } from 'src/app/services/user-settings.service';
import { environment } from '../../../environments/environment'
import {ScrollService} from "../../services/scroll.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'tt-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy{
  @ViewChild('outlet', {static: true}) outlet!: ElementRef
  trackingService = inject(TrackingService);
  fronteggAuthService = inject(FronteggAuthService)
  fronteggAppService = inject(FronteggAppService)
  userInfoService = inject(UserInfoService);
  subscription = new Subscription()
  private http = inject(HttpClient)
  scrollService = inject(ScrollService);
  userSettingsService = inject(UserSettingsService)
  loadingSubscription: Subscription;
  isLoading = true;
  isChooserDisplayed = false;
  isTenantSelected: any;
  showFooter = true
  tenants: any[] = []
  activeTenantId: any;

  constructor(angulartics2Segment: Angulartics2Segment, router: Router) {
    this.isTenantSelected = localStorage.getItem('isTenantSelected')
    this.loadingSubscription = this.fronteggAppService.isLoading$.subscribe((isLoading) => this.isLoading = isLoading)
    angulartics2Segment.startTracking();
    router.events.pipe(filter((routerevent: Event) => routerevent instanceof Scroll))
      .subscribe(() => {
        if(this.outlet){
          this.outlet.nativeElement.scrollTop = 0
        }
      })
      router.events.pipe(filter((routerevent: Event) => routerevent instanceof NavigationEnd),
        map((res:any) => res.url != '/expert-view'))
        .subscribe((res: any) => this.showFooter = res)

  }
  ngOnInit(): void {
    this.fronteggAuthService.tenantsState$.subscribe(tenantState => {
      this.tenants = tenantState.tenants;
      if(tenantState.tenants.length > 1){
        this.isChooserDisplayed = true
      }
    })
    this.fronteggAuthService.authState$.subscribe(authState => {
      if (!authState.isAuthenticated) {
        this.fronteggAuthService.loginWithRedirect();
      }
      if(authState.user) {
        this.trackingService.trackUser(authState.user);
      }
    });
    this.userInfoService.getUser.subscribe(res => {
      this.trackingService.trackUser(res);
    })
    this.subscription.add(
      // yomaApiURL
      this.http.get(`${environment.yomaApiURL}/user/settings`).subscribe( (res: any) => {
        this.userSettingsService.setUserSettings(res)
      })
    )

    this.scrollService.scrollToTop$.subscribe(() => {
      this.outlet.nativeElement.scrollTop = 0;
    });
  }

  setActiveTenant(tenantId: any) {
    this.activeTenantId = tenantId;
  }

  switchTenant(): void {
    localStorage.setItem('isTenantSelected', 'true')
    this.isTenantSelected = 'true'
    this.fronteggAuthService.switchTenant({ tenantId: this.activeTenantId })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
