<nz-card class="footer page">
  <div class="footer-content">
    <div class="footer-logo">
      <img src="assets/logo_yoma-2.svg" alt="logo">
      <p nz-typography class="contact-address">
        Gutenbergring 67a <br>
        22848 Norderstedt<br>
        Deutschland
      </p>
    </div>
    <div class="footer-contacts">
      <h3 nz-typography class="footer-contact-header">Kontakt</h3>
      <div class="contact-links">
        <a nz-typography class="contact-field" href="tel:04033480490" target="_blank">
          <span nz-icon nzType="phone" nzTheme="outline" class="contact-icon"></span>
          <span>040 33 48 04 90</span>
        </a>
        <a nz-typography class="contact-field" href="mailto:info@yoma-solutions.de" target="_blank">
          <span nz-icon nzType="mail" nzTheme="outline" class="contact-icon"></span>
          <span>info&#64;yoma-solutions.de</span>
        </a>
        <a nz-typography class="contact-field" href="https://www.linkedin.com/company/yomagroup/" target="_blank">
          <span nz-icon nzType="linkedin" nzTheme="outline" class="contact-icon"></span>
          <span>yomagroup</span>
        </a>
      </div>
    </div>
    <div class="footer-about">
      <h3 class="footer-about-header">Wo sitzt YOMA?</h3>
      <span class="about-description">
        Unser Hauptsitz liegt direkt am Flughafen von Hamburg.
        Geografisch gehören wir noch zum südlichen Rand von Schleswig-Holstein.
        Wir haben die Prozesse soweit digitalisiert, dass wir das komplette Onboarding
        per Telefon oder Video-Konferenz umsetzen können.
        Zu persönlichen Treffen kommen wir gerne.
      </span>
    </div>
  </div>
  <div class="footer-links">
    <div class="copyright">YOMA SOLUTIONS GMBH © {{currentYear}}</div>
    <div class="links">
      <a nz-button nzType="link" href="https://yoma-track-trace.com/impressum/" target="_blank" class="link" (click)="impresumClicked()">IMPRESSUM</a>
      <a nz-button nzType="link" href="https://yoma-track-trace.com/datenschutzerklaerung/" class="link" target="_blank" (click)="datenschutzClicked()">DATENSCHUTZ</a>
      <a nz-button nzType="link" href="https://yoma-track-trace.com/cookie-richtlinie-eu/" class="link" target="_blank" (click)="cookiesClicked()">COOKIES</a>
    </div>
  </div>
</nz-card>
