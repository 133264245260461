<div class="statistics-content">
  <div class="profile-card card">
    <div class="profile-info-wrapper">
      <nz-avatar [nzSize]="128" nzIcon="user" class="profile-avatar"></nz-avatar>
      <h3 nz-typography> Hey, Carlos Robbie</h3>
      <span nz-typography nzType="secondary" class="profile-location">
        <span nz-icon nzType="environment" nzTheme="outline"></span>
        Hamburg, Germany
      </span>
    </div>
    <div class="profile-statistics-wrapper">
      <div class="profile-statistics-item">
        <span nz-typography nzType="secondary" class="statistic-item-title">Ordered</span>
        <span nz-typography class="statistic-item-value">68</span>
      </div>
      <div class="profile-statistics-item">
        <span nz-typography nzType="secondary" class="statistic-item-title">Closed Orders</span>
        <span nz-typography class="statistic-item-value">214</span>
      </div>
      <div class="profile-statistics-item">
        <span nz-typography nzType="secondary" class="statistic-item-title">Total Orders</span>
        <span nz-typography class="statistic-item-value">282</span>
      </div>
    </div>
    <button nz-button nzType="text" class="profile-card-btn" (click)="showAdminPortal()">Profile Settings <span nz-icon nzType="arrow-right" nzTheme="outline"></span></button>
  </div>
  <div class="chart-card card">
    <div id="chart">
      <h2 nz-typography>Vehicle Delivery Statistics</h2>
      <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [xaxis]="chartOptions.xaxis"
        [yaxis]="chartOptions.yaxis"
        [plotOptions]="chartOptions.plotOptions"
        [colors]="chartOptions.colors"
        [legend]="chartOptions.legend"
      ></apx-chart>
    </div>
  </div>
</div>
