import { CommonModule } from '@angular/common';
import {Component, Input, OnDestroy, OnInit, inject} from '@angular/core';
import { RouterModule } from '@angular/router';
import { differenceInCalendarDays } from 'date-fns';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import {Subscription, forkJoin,of,switchMap, take} from 'rxjs';
import { FavoriteVehiclesModule } from 'src/app/directives/favorite-vehicle.module';
import {VehicleFilters, YomaQueryParams} from 'src/app/models/filters';
import {VehicleStatus} from 'src/app/models/vehicleStatus';
import { Text2SlugPipe } from 'src/app/pipes/text2-slug.pipe';
import { URL2base64Pipe } from 'src/app/pipes/url2base64.pipe';
import { YomaDateFormatPipe } from 'src/app/pipes/yoma-date-format.pipe';
import { FavoriteVehicleService } from 'src/app/services/favorite-vehicle.service';
import {NzNotificationService} from "ng-zorro-antd/notification";
import {VehicleService} from 'src/app/services/vehicle.service';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'tt-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss'],
  standalone: true,
  imports: [ CommonModule, NzCardModule, YomaDateFormatPipe, Text2SlugPipe, NzTypographyModule, NzSpinModule, NzListModule, NzEmptyModule, RouterModule, URL2base64Pipe, FavoriteVehiclesModule, NzButtonModule, NzIconModule, NzToolTipModule ]
})
export class DashboardCardComponent implements OnInit, OnDestroy {
  @Input() color: string = ""
  @Input() title: string = ""
  @Input() type: string = ""
  @Input() sort: string = ""
  @Input() isViewMoreBtnVisible: boolean = true
  @Input() filters: any = {};
  private subscription = new Subscription()
  vehicleService = inject(VehicleService)
  favouriteVehiclesService = inject(FavoriteVehicleService)
  breakpointObserver = inject(BreakpointObserver)
  notification = inject(NzNotificationService)

  param: YomaQueryParams = {limit: 20}
  vehicleData: VehicleStatus[] = []
  favouritesVehiclesID: any[] = [];
  isLoading = false;
  tooltipTitleText = "";
  isMobileView: boolean = false;
  isErrorDisplayed = false;

  formatter = new Intl.NumberFormat('de', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  });

  ngOnInit(): void {
    this.subscription.add(
      this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.WebPortrait]).subscribe((state: BreakpointState) => {
        this.isMobileView = state.matches;
      })
    )
    this.setToolTipTitle();
    if (this.sort) {
      this.param.sort = this.sort;
    }
    if (this.type === "favourites") {
      this.getFavorites()
    } else {
      this.isLoading = true
      this.subscription.add(
        this.vehicleService.getVehicleStatus(this.type, this.param).subscribe({
          next: res => {
            this.isLoading = false;
            this.vehicleData = res.data.sort((a: VehicleStatus, b: VehicleStatus) => {
              if (a.delivery_date_updated_date && b.delivery_date_updated_date) {
                return new Date(a.delivery_date_updated_date) < new Date(b.delivery_date_updated_date) ? -1 : 1;
              }
              return 0;
            });
          },
          error: err => {
            this.notification.error(
              "Fehler",
              `${err.error.error_message}`,
            )
            this.isErrorDisplayed = true;
            this.isLoading = false;
          }
        })
      );
    }
  }
  setFilters(){
    if (this.type === "pending"){
      this.vehicleService.statusFilter = "pending"
    }
    if(this.type === "changes_to_delivery_date" && this.sort){
      this.vehicleService.statusFilter = "delayed";
      this.vehicleService.sort = this.sort;
    }
    if(this.type === "favourites"){
      this.vehicleService.archiveFilter = "all"
      this.vehicleService.isFavouriteFilterActive = true;
    }
  }

  setToolTipTitle() {
    switch (this.type) {
      case 'favourites' :
        this.tooltipTitleText = "Alle Fahrzeuge, die du als Favorit markiert hast";
        break
      case 'pending' :
        this.tooltipTitleText = "Auslieferung an Zieladresse  in den nächsten Tagen";
        break
      case 'changes_to_delivery_date':
        if (this.sort == "desc") {
          this.tooltipTitleText = "Hersteller hat das ursprünglich geplante Lieferdatum nach hinten verschoben"
        }
        if (this.sort == "asc") {
          this.tooltipTitleText = "Hersteller hat das ursprünglich geplante Lieferdatum nach vorne verschobe"
        }
        break
    }
  }

  getFavorites() {
    this.isLoading = true;
    // this.param.limit = 100;
    this.vehicleService.getVehicleStatus(this.type).pipe(
      take(1),
      switchMap((favVehIDs: any) => {
        if (!favVehIDs.length) {
          return of([]);
        }
        return forkJoin(favVehIDs.map((id: string) => this.vehicleService.getVehicleDetails(id)));
      }),
    ).subscribe({
      next: (res: any) => {
        this.vehicleData = res;
        this.isLoading = false;
      },
      error: err => {
        this.notification.error(
          "Fehler",
          `${err.error.error_message}`,
        )
        this.isErrorDisplayed = true;
        this.isLoading = false;
      }
    });
  }

  setBadgeText(daysCount: any){
    if(daysCount || daysCount == 0){
      let days = Math.abs(+daysCount)
      let text = "";
      switch (days){
        case 0: text = "Heute";
          break
        case 1:  text ="1 Tag";
          break
        default: text = this.formatter.format(days) + " Tage"
      }
      return text
    } else {
      return
    }
  }

  setDeliveryDay(days?: string) {
    let info
    if(days == null) {
      info = ''
    } else {
      let interval = this.getDays(days!)
      switch (interval) {
        case 0:
          info = 'Lieferung heute'
          break;
        case 1:
          info = 'Lieferung morgen'
          break;
        default:
          info = `Auslieferung in ${interval} Tagen`
          break;
      }
    }
    return info
  }

  setDeliveryDayTillToday(current_delivery_date_till_today?: number) {
    let info
    if(!current_delivery_date_till_today) {
      info = ''
    } else {
      // let interval = differenceInCalendarDays(new Date(date), new Date(date_updated))
      switch (true) {
        case (current_delivery_date_till_today < 0):
          info = `${Math.abs(current_delivery_date_till_today)} Tage zu früh`
          break;
        case (current_delivery_date_till_today > 0):
          info = `${current_delivery_date_till_today} Tage Verspätung`
          break;
        default:
          info = `Auslieferung in ${current_delivery_date_till_today} Tagen`
          break;
      }
    }
    return info
  }

  setFavDeliveryDay(days?: string) {
    let info
    if(days == null) {
      info = ''
    } else {
      let interval = this.getDays(days!)
      switch (interval) {
        case 0:
          info = 'Lieferung heute'
          break;
        case 1:
          info = 'Lieferung morgen'
          break;
        default:
          info = `Auslieferung in ${interval} Tagen`
          break;
      }
    }
    return info
  }

  getDays(date?: string) {
    if(date) {
      return differenceInCalendarDays(new Date(date), new Date())
    }
    return
  }

  getProductionOrderId(value: string) {
    let idx = value.lastIndexOf("#") + 1;
    return value.substring(idx);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
