import {Component, inject, OnInit} from '@angular/core';
import {switchMap, catchError, throwError} from 'rxjs';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexYAxis,
  ApexPlotOptions,
  ApexLegend,
  ApexFill,
  ApexStroke,
  NgApexchartsModule, ApexDataLabels,
} from "ng-apexcharts";
import {NzIconDirective} from "ng-zorro-antd/icon";
import {FronteggAppService} from "@frontegg/angular";
import {VehicleService} from "../../services/vehicle.service";
import {CommonModule} from "@angular/common";
import {UserInfoService} from "../../services/user-info.service";


export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  plotOptions: ApexPlotOptions;
  stroke?: ApexStroke;
  fill?: ApexFill;
  colors: string[];
  legend: ApexLegend;
  dataLabels: ApexDataLabels;
};

@Component({
  selector: 'tt-statistics-chart',
  standalone: true,
  imports: [NgApexchartsModule, NzTypographyModule, NzCardModule, NzAvatarModule, NzButtonModule, NzIconDirective, NzIconModule, NzSpinModule, CommonModule],
  templateUrl: './statistics-chart.component.html',
  styleUrl: './statistics-chart.component.scss'
})
export class StatisticsChartComponent implements OnInit{

  fronteggAppService = inject(FronteggAppService)
  userInfoService = inject(UserInfoService)
  vehicleService = inject(VehicleService)
  user: any | undefined
  address: any | undefined
  public chartOptions : ChartOptions | undefined;
  isLoading = true;

  constructor() {}

  ngOnInit(): void {
    this.userInfoService.getUser.subscribe(res => {
      this.user = res;
      this.getAddress(res.metadata);
    });

    this.vehicleService.getVehiclesStatistics()
      .pipe(
        switchMap(statistics => {
          // this.isLoading = true;
          return this.vehicleService.getVehiclesStatisticsById(statistics[0].id)
            .pipe(
              catchError(err => {
                console.error(err);
                return throwError(() => err);
              })
            );
        }),
        catchError(err => {
          console.error(err);
          return throwError(() => err);
        })
      )
      .subscribe({
        next: statistic => {
          this.isLoading = false;
          this.chartOptions = {
            series: [
              {
                name: "Bestelleingänge",
                data: statistic.result[0].data.reverse(),

              },
              {
                name: "Auslieferungen",
                data: statistic.result[1].data.reverse()
              }
            ],
            chart: {
              type: "bar",
              height: 350
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: "80%",
                borderRadius: 5,
                borderRadiusApplication: 'end',
                borderRadiusWhenStacked: "last"
              }
            },
            colors: ["#000", "#01b574"],
            xaxis: {
              categories: this.formatDates(statistic.result[0].categories.reverse())
            },
            yaxis: {
            },
            legend: {
              position: "top",
              fontSize: "14px",
              fontWeight: 500,
              fontFamily: "Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif",
              labels: {
                useSeriesColors: true,
              }
            },
            dataLabels: {
              enabled: false,
            }
          };
        },
        error: err => {
          this.isLoading = false;
        }
      });
  }

  showAdminPortal(): void {
    this.fronteggAppService?.showAdminPortal()
  }

  formatDates(dates: any[]){
    const months: { [key: string]: string } = {
      "January": "Jan.",
      "February": "Feb.",
      "March": "Mrz.",
      "April": "Apr.",
      "May": "Mai.",
      "June": "Jun.",
      "July": "Jul.",
      "August": "Aug.",
      "September": "Sep.",
      "October": "Okt.",
      "November": "Nov.",
      "December": "Dez."
    };

    return dates.map((date: any) => {
      const d = new Date(date);
      // return d.toLocaleString('de-DE', { month: 'short', year: 'numeric' });
      const monthName = d.toLocaleString('en-US', { month: 'long' });
      const monthShort = months[monthName] || monthName;
      // const year = d.getFullYear();
      return `${monthShort}`;
    });
  }

  getAddress(data: any){
    const metadata = JSON.parse(data)
    this.address = [metadata.address?.address1, metadata.address?.city, metadata.address?.state, metadata.address?.postCode, metadata.address?.country].filter(Boolean).join(", ");
  }

  isAdmin() {
    return this.user.roles.some((role: any) => role.key == "Admin")
  }
}
