import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../environments/environment'
import { KeyAccount } from 'src/app/models/keyaccount';
import { Observable } from 'rxjs';
import { Buffer } from 'buffer';

@Injectable({
  providedIn: 'root'
})
export class KeyAccountService {

  http = inject(HttpClient)

  getKeyAccounts(): Observable<KeyAccount[]> {
    return this.http.get<KeyAccount[]>(`${environment.yomaApiURL}/user/keyaccountuser`)
  }

  getKeyAccount(id: string): Observable<KeyAccount> {
    return this.http.get<KeyAccount>(`${environment.yomaApiURL}/user/keyaccountuser/${id}`)
  }

  setKeyAccount(acc: KeyAccount): Observable<any> {
    return this.http.put(`${environment.yomaApiURL}/user/keyaccountuser`, acc)
  }

  updateKeyAccount(id: string, data: any): Observable<any> {
    return this.http.put(`${environment.yomaApiURL}/user/keyaccountuser/${id}`, data)
  }

  deleteKeyAccount(id: string): Observable<any> {
    return this.http.delete(`${environment.yomaApiURL}/user/keyaccountuser/${id}`)
  }

  getKeyAccountVehicles(id: string): Observable<any> {
    id = Buffer.from(id).toString('base64')
    return this.http.get<KeyAccount>(`${environment.yomaApiURL}/user/keyaccountuser/${id}/preview`)
  }
}
