<div nz-page-header class="page-header">
  <div class="header-container">
    <div class="menu-wraper">
      <a class="header-logo" routerLink="">
        <img nz-page-header-avatar src="assets/logo.png" alt="img"/>
      </a>
      <ul class="menu-lg" nz-menu nzMode="horizontal">
        <li nz-menu-item routerLink="">
          <img nz-page-header-avatar ngSrc="assets/logo.png" alt="img" height="80" width="138"/>
        </li>
        <li *ngIf="hasFeature('feature-news-feed')" nz-menu-item nzMatchRouter routerLink="/news"><h3>NEWS</h3></li>
        <li *ngIf="hasFeature('feature-vehicles')" nz-menu-item nzMatchRouter routerLink="/vehicles">
          <h3>Meine Fahrzeuge</h3>
        </li>
        <li *ngIf="hasFeature('feature-expert-view')" nz-menu-item nzMatchRouter routerLink="/expert-view">
          <h3>Expertensicht</h3>
        </li>
        <li *ngIf="hasFeature('feature-new-vehicle-monitor')" nz-menu-item nzMatchRouter routerLink="/neuwagenmonitor"
            data-testid="menu-item-neuwagenmonitor">
          <h3>Neuwagenmonitor</h3>
        </li>
      </ul>
    </div>
    <nz-space class="menu-space" *ngIf="user">
      <nz-space class="menu-lg">
        <nz-avatar
          *nzSpaceItem
          nzIcon="user"
          nzSize="large"
          nzSrc="{{ user.picture }}"
        ></nz-avatar>
        <div *nzSpaceItem class="user-menu">
          <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
            <div class="user-info-menu-item">
              <span nz-typography class="user-name">Hey {{ user.name }}!</span>
              <span nz-typography nzType="secondary">{{tenantState.activeTenant?.name }}</span>
            </div>
            <span nz-icon nzType="down"></span>
          </a>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu>
              <li nz-menu-item class="bordered">
                <span nz-icon nzType="account_circle" nzTheme="outline" class="menu-icon"></span>
                <span (click)="showAdminPortal()">Profil & Benutzerverwaltung</span>
              </li>
              <li *ngIf="isAdmin()" nz-menu-item>
                <span nz-icon nzType="toggle_on" nzTheme="outline" class="menu-icon"></span>
                <a [routerLink]="['features']">Features</a>
              </li>
              <li *ngIf="hasFeature('feature-keyaccount') && isAdmin()" nz-menu-item>
                <span nz-icon nzType="table_eye" nzTheme="outline" class="menu-icon"></span>
                <a [routerLink]="['keyaccount']">Kunden & Partner</a>
              </li>
              <li *ngIf="isAdmin()" nz-menu-item>
                <span nz-icon nzType="edit" nzTheme="outline" class="menu-icon"></span>
                <a [routerLink]="['change-column-names']">Spaltennamen ändern</a>
              </li>
              <li *ngIf="isAdmin()" nz-menu-item>
                <span nz-icon nzType="power" nzTheme="outline" class="menu-icon"></span>
                <a [routerLink]="['developer']" (click)="developerClicked()">API Schnittstelle</a>
              </li>
              <li nz-menu-item class="bordered" *ngIf="isAdmin()">
                <span nz-icon nzType="input_circle" nzTheme="outline" class="menu-icon"></span>
                <a [routerLink]="['importers']">Importers</a>
              </li>
              <li nz-menu-item class="user-menu-item-link">
                <div class="menu-item-content">
                  <div class="menu-item">
                    <span nz-icon nzType="menu_book" nzTheme="outline" class="menu-icon"></span>
                    <a href="https://yoma-track-trace.helpjuice.com/" target="_blank" (click)="supportClicked()">
                      Support
                    </a>
                  </div>
                  <span nz-icon nzType="open_in_new" nzTheme="outline"></span>
                </div>
              </li>
              <li nz-menu-item>
                <span nz-icon nzType="logout" nzTheme="outline" class="menu-icon"></span>
                <span (click)="logout()">Ausloggen</span>
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </nz-space>
      <div class="mobile-menu">
        <ul nz-menu nzMode="horizontal" style="display: flex">
          <li nz-menu-item routerLink="/" [nzSelected]="isHomeRouteActive()">
            <div class="mobile-menu-item">
              <span nz-icon nzType="home" class="mobile-menu-icon test"></span>
              <span class="menu-text-item">Start</span>
            </div>
          </li>
          <li *ngIf="hasFeature('feature-news-feed')" nz-menu-item routerLink="/news" nzMatchRouter>
            <div class="mobile-menu-item">
              <span nz-icon nzType="newsstand" class="mobile-menu-icon"></span>
              <span class="menu-text-item">News</span>
            </div>
          </li>
          <li *ngIf="hasFeature('feature-vehicles')" nz-menu-item (click)="openSearchModal(tplContent, tplFooter)" nzMatchRouter>
            <div class="mobile-menu-item">
              <span nz-icon nzType="search" class="mobile-menu-icon" style="font-size: 30px; margin-top: 2px"></span>
              <span class="menu-text-item">Suche</span>
            </div>
          </li>
          <li *ngIf="hasFeature('feature-vehicles')" nz-menu-item routerLink="/vehicles" nzMatchRouter>
            <div class="mobile-menu-item">
              <span nz-icon nzType="directions_car" class="mobile-menu-icon"></span>
              <span class="menu-text-item">Fahrzeuge</span>
            </div>
          </li>
          <li nz-menu-item (click)="open()" nzMatchRouter>
            <div class="mobile-menu-item">
              <span nz-icon nzType="menu" class="mobile-menu-icon"></span>
              <span class="menu-text-item">Menü</span>
            </div>
          </li>
        </ul>
      </div>
      <nz-avatar
        class="display-mobile"
        *nzSpaceItem
        nzIcon="user"
        nzSize="large"
        nzSrc="{{ user.picture }}"
      ></nz-avatar>
      <button class="menu-button hide-mobile" nz-button nzType="text" (click)="open()">
        <span nz-icon nzType="menu-fold" class="font-2x"></span>
      </button>
      <nz-drawer
        [nzClosable]="true"
        [nzVisible]="visible"
        [nzPlacement]="placement"
        nzTitle="Menu"
        (nzOnClose)="close()"
      >
        <ng-container *nzDrawerContent>
          <ul nz-menu (click)="close()">
            <li nz-menu-item nzMatchRouter routerLink="/home"><h3>Home</h3></li>
            <li *ngIf="hasFeature('feature-news-feed')" nz-menu-item routerLink="/keyaccount"><h3>NEWS</h3></li>
            <li *ngIf="hasFeature('feature-vehicles')" nz-menu-item routerLink="/vehicles"><h3>Meine Fahrzeuge</h3></li>
            <li *ngIf="hasFeature('feature-new-vehicle-monitor')" nz-menu-item routerLink="/neuwagenmonitor"><h3>
              Neuwagenmonitor</h3></li>
            <li *ngIf="hasFeature('feature-expert-view')" nz-menu-item routerLink="/expert-view"><h3>Expertensicht</h3>
            </li>
            <li *ngIf="hasFeature('feature-keyaccount')" nz-menu-item routerLink="/keyaccount"><h3>Kunden & Partner</h3></li>
            <!--            <li nz-menu-item>-->
            <!--              <a [routerLink]="['developer']"><h3>API Schnittstelle</h3></a>-->
            <!--            </li>-->
            <!--            <li nz-menu-item>-->
            <!--              <a [routerLink]="['settings']"><h3>Einstellungen</h3></a>-->
            <!--            </li>-->
            <li nz-menu-item>
              <h3 (click)="showAdminPortal()">Profil & Benutzerverwaltung</h3>
            </li>
            <li nz-menu-item *ngIf="isAdmin()">
              <h3 [routerLink]="['features']">Features</h3>
            </li>
            <li *ngIf="isAdmin()" nz-menu-item>
              <h3 [routerLink]="['developer']" (click)="developerClicked()">API Schnittstelle</h3>
            </li>
            <li *ngIf="isAdmin()" nz-menu-item>
              <h3 [routerLink]="['importers']">Importers</h3>
            </li>

          </ul>
          <div class="drawer-buttons">
            <a href="https://yoma-track-trace.helpjuice.com/" target="_blank" nz-button nzType="primary">
              Support
            </a>
            <button
              class="logout-button"
              nz-button
              nzType="primary"
              nzDanger
              (click)="logout()"
            >
              Ausloggen
            </button>
          </div>
        </ng-container>
      </nz-drawer>
    </nz-space>
    <ng-template #tplContent>
      <form nz-form>
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24">Marke</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <nz-select
              class="info-select"
              [(ngModel)]="selectedBrand"
              nzPlaceHolder="Beliebig"
              [ngModelOptions]="{standalone: true}"
            >
              <nz-option *ngFor="let brand of vehicleService.vehicleBrands" nzValue="{{brand}}" nzLabel="{{brand}}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24">Hersteller Bestell-Nr.</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <input nz-input [(ngModel)]="selectedOrderId" placeholder="Beliebig"
                   [ngModelOptions]="{standalone: true}"/>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24">Auftragstyp</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <nz-select
              class="info-select"
              [(ngModel)]="selectedOrderType"
              nzPlaceHolder="Beliebig"
              [ngModelOptions]="{standalone: true}"
            >
              <nz-option *ngFor="let orderType of vehicleService.vehicleOrderTypes" nzValue="{{orderType}}"
                         nzLabel="{{orderType}}"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSm]="24" [nzXs]="24">Kundenname</nz-form-label>
          <nz-form-control [nzSm]="24" [nzXs]="24">
            <input nz-input [(ngModel)]="selectedCustomerName" placeholder="Beliebig"
                   [ngModelOptions]="{standalone: true}"/>
          </nz-form-control>
        </nz-form-item>
      </form>
    </ng-template>
    <ng-template #tplFooter let-ref="modalRef">
      <button nz-button (click)="handleModalClose(ref)">Abbrechen</button>
      <button nz-button nzType="primary" nzDanger routerLink="/vehicles" (click)="setFilterData(ref)">Suche</button>
    </ng-template>
  </div>
</div>

