export const environment = {
  production: false,
  baseApiBasePath: 'https://api.dev.yomagroup.info/base/v1',
  logisticsApiBasePath: 'https://api.dev.yomagroup.info/logistics/v1',
  infleetingApiBasePath: 'https://api.dev.yomagroup.info/infleeting/v1',
  matchmakerApiBasePath: 'https://api.dev.yomagroup.info/mm',
  customFieldsApiBasePath: 'https://api.dev.yomagroup.info/custom_fields/v1',
  cardsApiBasePath: 'https://api.dev.car-tracking-online.com/v1',
  show_svg_timeline: true,
  agGridLicenseKey: '',
  segmentWriteKey: '',
  sendTrackingEvents: false,
  buildTime: Date.now(),
  dataApiURL: 'https://api.data.dev.yoma-track-trace.com',
  dataApiDocsURL: 'https://api.data.dev.yoma-track-trace.com/docs/',
  yomaApiURL: 'https://api.platform.dev.yoma-track-trace.com',
  yomaApiDocsURL: 'https://api.platform.dev.yoma-track-trace.com/docs/',
  yomaGuestTenantId: '62fa81c2-1268-4ca8-a9a2-bcecd1d07408',
  fronteggClientId: 'f6c3f1b0-abcc-4659-8e4b-b4a45f666ec5',
  fronteggBaseUrl: 'https://auth.platform.dev.yoma-track-trace.com',
  fleetMonitorUrl: 'https://x11xsgpwdd.execute-api.eu-central-1.amazonaws.com/v1'
}
