@if (isChooserDisplayed && !isTenantSelected){
  <div class="tenant-chooser">
    <div class="tenant-chooser-card">
      <img ngSrc="assets/logo.png" alt="img" height="53" width="86"/>
      <div class="tenant-text-section">
        <h2 nz-typography class="tenant-card-title" >Mandant wählen</h2>
        <p nz-typography>Ihre E-Mail ist mit mehreren Mandaten verbunden. Bei welcher möchten Sie sich anmelden?</p>
      </div>
      <div class="tenant-chooser-items">
        <div
          class="tenant-chooser-item"
          *ngFor=" let tenant  of tenants"
          [class.active]="tenant.tenantId === activeTenantId"
          (click)="setActiveTenant(tenant.tenantId)"
        >
          {{tenant.name}}
          <span nz-icon nzType="check-circle" nzTheme="fill" class="tenant-item-icon"></span>
        </div>
      </div>
      <button nz-button nzType="primary" nzDanger (click)="switchTenant()">Anmelden</button>
    </div>


  </div>
} @else {
  <tt-page-header></tt-page-header>
  <div class="layout-wrapper" #outlet>
    <router-outlet></router-outlet>
    @if (showFooter) {
      <tt-page-footer></tt-page-footer>
    }
  </div>
}
