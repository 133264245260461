import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './components/layout/layout.component';
import { authGuard } from './guards/auth.guard';
import { MyVehicleComponent } from './pages/my-vehicle/my-vehicle.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { NotFoundComponent } from "./pages/not-found/not-found.component";

const routes: Routes = [
  // { path: '', component: LayoutComponent, canActivate: [AuthGuard],
  { path: '', component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./components/layout/layout.module').then(x => x.LayoutModule)
      }
    ],
    canActivate: [authGuard]
  },
  { path: 'myvehicle/:vehicleId', component: MyVehicleComponent },
  { path: 'register', component: RegisterPageComponent },
  { path: 'register/:status', component: RegisterPageComponent },
  { path: 'not-found', component: NotFoundComponent },
  // { path: '**', component: NotFoundComponent }
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
