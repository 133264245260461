<div class="statistics-content">
  <div class="profile-card card">
    <div class="profile-info-wrapper">
      <nz-avatar [nzSize]="128" nzIcon="user" nzSrc="{{ user.profilePictureUrl }}" class="profile-avatar"></nz-avatar>
      <h3 nz-typography style="text-align: center"> Hey, {{user.name}}</h3>
      <span nz-typography nzType="secondary" class="profile-location" *ngIf="address">
        <span nz-icon nzType="environment" nzTheme="outline"></span>
        {{address}}
      </span>
    </div>
    <button nz-button nzType="text" class="profile-card-btn" (click)="showAdminPortal()">{{ isAdmin() ? 'Profil & Benutzerverwaltung' : 'Profilverwaltung'}} <span nz-icon nzType="arrow-right" nzTheme="outline"></span></button>
  </div>
  <div class="chart-card card">
    <nz-spin nzSimple *ngIf="this.isLoading" class="spinner"></nz-spin>
    <div id="chart">
      <apx-chart
        *ngIf="chartOptions"
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [xaxis]="chartOptions.xaxis"
        [yaxis]="chartOptions.yaxis"
        [plotOptions]="chartOptions.plotOptions"
        [colors]="chartOptions.colors"
        [legend]="chartOptions.legend"
        [dataLabels]="chartOptions.dataLabels"
      ></apx-chart>
    </div>
  </div>
</div>
