<div class="cards-wrapper">
  <div class="column-header" nz-tooltip [nzTooltipTitle]="tooltipTitleText">
    <span>{{title}}</span>
    @if(isMobileView) {
      <button nz-button nzType="text" (click)="setFilters()" routerLink="/vehicles">
        <span nz-icon nzType="arrow_forward_ios" nzTheme="outline"></span>
      </button>
    } @else {
      <span class="info-icon" nz-icon nzType="info-circle"></span>
    }
  </div>
  <div class="cards-body fg16">
    <nz-spin class="dashboard-card-loader" *ngIf="isLoading"  nzSimple [nzSize]="'large'"></nz-spin>
    <nz-empty *ngIf="vehicleData.length === 0 && !isLoading" class="empty"></nz-empty>
    @for (item of vehicleData; track $index) {
      @if(!isMobileView) {<hr/>}
      <a nz-icon [routerLink]="['/vehicle', item.id! | url2base64]" target="_blank" [className]="isMobileView ? 'mobile' : '' ">
        <div class="card fg16">
          <span class="item-spacer" [style.background]="color"></span>
          <div class="card-content fg16">
            <ng-container [ngSwitch]="type">
              <ng-container *ngSwitchCase="'favourites'">
                <div class="card-header">
                  {{item.cardealer_customer_name}}
                </div>
                <div class="fg16">
                  <div class="car-img">
                    <img height="34" src="https://cdn.imagin.studio/getImage?customer=yoma-de&make={{item.vehicle_brand}}&modelFamily={{item.vehicle_model_normalized}}&angle=56&zoomType=fullscreen&paintdescription={{item.vehicle_color! | text2Slug}}"/>
                  </div>
                  <div class="car-model">
                    <div>{{item.vehicle_brand}} {{item.vehicle_model_normalized}}</div>
                    <div>Nr.: {{item.production_order_id_short}}</div>
                  </div>
                </div>
                <div class="car-info">
                  <div class="car-status green">
                    <strong>{{setFavDeliveryDay(item.current_delivery_date_planned)}}</strong>
                    @if(item.current_delivery_date_till_today == 0) {
                      <span nz-icon nzType="check_circle" nzTheme="fill"></span>
                    }
                  </div>
                  <div class="car-delivery">Ursprünglich geplant: {{item.delivery_date_cardealer_planned_at_order_time != null ?
                    (item.delivery_date_cardealer_planned_at_order_time | yomaDateFormat: "dd.MM.yyyy" ) : "--"}}</div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'pending'">
                <div class="card-header">
                  {{item.cardealer_customer_name}}
                </div>
                <div class="fg16">
                  <div class="car-img">
                    <img height="34" src="https://cdn.imagin.studio/getImage?customer=yoma-de&make={{item.vehicle_brand}}&modelFamily={{item.vehicle_model_normalized}}&angle=56&zoomType=fullscreen&paintdescription={{item.vehicle_color! | text2Slug}}"/>
                  </div>
                  <div class="car-model">
                    <div>{{item.vehicle_brand}} {{item.vehicle_model_normalized}}</div>
                    <div>Nr.:{{item.production_order_id_short}}</div>
                  </div>
                </div>
                <div class="car-info">
                  <div class="car-status green">
                    <strong>{{setDeliveryDay(item.current_delivery_date_planned)}}</strong>
                    @if(getDays(item.current_delivery_date_planned) == 0) {
                      <span nz-icon nzType="check_circle" nzTheme="fill"></span>
                    }
                  </div>
                  <div class="car-delivery">Ursprünglich geplant: {{item.delivery_date_cardealer_planned_at_order_time != null ?
                    (item.delivery_date_cardealer_planned_at_order_time | yomaDateFormat: "dd.MM.yyyy" ) : "--"}}</div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'changes_to_delivery_date'">
                <div class="card-header">
                  {{item.cardealer_customer_name}}
                </div>
                <div class="fg16">
                  <div class="car-img">
                    <img height="34" src="https://cdn.imagin.studio/getImage?customer=yoma-de&make={{item.vehicle_brand}}&modelFamily={{item.vehicle_model_normalized}}&angle=56&zoomType=fullscreen&paintdescription={{item.vehicle_color! | text2Slug}}"/>
                  </div>
                  <div class="car-model">
                    <div>{{item.vehicle_brand}} {{item.vehicle_model_normalized}}</div>
                    <div>Nr.: {{item.production_order_id_short}}</div>
                  </div>
                </div>
                <div class="car-info">
                  <div class="car-status" [style.color]="color">
                    <strong>{{setDeliveryDayTillToday(item.traffic_light_indicator)}}</strong>
                    <span nz-icon nzType="warning" nzTheme="fill"></span>
                  </div>
                  <div class="car-delivery">Neuer Liefertermin: {{item.delivery_date_cardealer_planned_update != null ?
                    (item.delivery_date_cardealer_planned_update | yomaDateFormat: "dd.MM.yyyy" ) : "--"}}</div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </a>
    }
  </div>
  @if(!isMobileView) {
    <div class="cards-footer">
      <button nz-button nzType="text" (click)="setFilters()" routerLink="/vehicles">
        Alle ansehen <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
      </button>
    </div>
  }
</div>
