import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translate',
  standalone: true
})
export class TranslatePipe implements PipeTransform {

  transform(value: string): string {
    switch (value) {
      case "Customer":
        return "Kunde";
      case "Stock":
        return "Lager";
      case "Other":
        return "Sonstige";
      case "yoma_delivery_to_cardealer_status":
        return "Anlieferung Handel";
      case "yoma_vehicle_registration_status":
        return "Zulassung";
      case "yoma_delivery_to_customer_status":
        return "Übergabe";
      case "active":
        return "Aktiv";
      case "archived":
        return "Archiviert";
      case "all":
        return "Alle";
      case "pending":
        return "Ausstehend";
      case "delayed":
        return "Verzögert";
      default: return value;
    }
  }

}
