<div class="page">
  <div class="page-content">
    <div class="content">
      <div class="features-header">
        <h1>Features</h1>
      </div>
      <div class="loader-container">
        <nz-skeleton
          [nzActive]="true"
          [nzLoading]="isLoading"
          [nzParagraph]="{ rows: 8 }"
        ></nz-skeleton>
      </div>
      <nz-spin [nzSpinning]="isModalLoading">
      <div *ngIf="!isLoading" class="features">
        <div class="feature-block" *ngFor="let feature of customersFeatures">
          <div class="feature-header">
            <span class="feature-title">{{feature.name}}</span>
            <span
              *ngIf="!feature.toggled_off"
              nz-icon class="check-icon"
              [nzType]="'check-circle'"
              [nzTheme]="'twotone'"
              [nzTwotoneColor]="'#52c41a'"
            ></span>
          </div>
          <p class="feature-description">{{feature.description}}</p>
          <div class="feature-actions-container">
            <button
              nz-button nzType="primary"
              (click)="toggleValue(feature)"
            >
              {{feature.toggled_off ? "Aktivieren" : "Deaktivieren"}}
            </button>
            <span>{{feature.price}} € pro Monat (Mindestlaufzeit: {{feature.renewal_period}} {{feature.renewal_period && feature.renewal_period > 1 ? 'Monate' : 'Monat' }})</span>
          </div>
        </div>
      </div>
      </nz-spin>
    </div>
  </div>
</div>
