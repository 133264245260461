export class VehicleStatus {
    id?: string;
    production_order_id_short?: string;
    vehicle_brand?: string;
    vehicle_model_normalized?: string;
    traffic_light_indicator?: number;
    yoma_delivery_date_end_customer_estimate?: string;
    current_delivery_date_planned?: string;
    current_delivery_date_till_today?: number;
    delivery_date_updated_date?: string;
    delivery_date_cardealer_planned_update?: string;
    delivery_date_cardealer_planned_at_order_time?: string;
    vehicle_color?: string;
    cardealer_customer_name?: string
}

export enum VehicleStatusType {
    DELAYED = "delayed",
    PENDING = "pending",
    FAVORITES = "favorites"
}

